<template>
  <div class="row">
    <EmptyState
      v-if="showBlankState"
      :to="{ name: 'invite-user' }"
      icon="people"
      :message="$gettext('You don\'t have any users')"
      :customButtonText="$gettext('Create a user')"
    ></EmptyState>
    <div v-show="!showBlankState" class="col-md-12">
      <ContentHeader :title="$gettext('Users')">
        <template #description>
          {{ userLimitMessage }}
        </template>
        <template #options>
          <router-link
            :to="{ name: 'subscription' }"
            class="btn btn-outline-primary mr-2"
          >
            <i class="fas fa-arrow-up mr-2"></i>
            <translate>Increase your limit</translate></router-link
          >
          <router-link
            :disabled="hasReachedProductLimit"
            class="btn btn-primary align-self-baseline"
            :to="{ name: 'invite-user' }"
            ><i class="fas fa-plus mr-2"></i>
            <translate>Add User</translate></router-link
          >
        </template>
      </ContentHeader>

      <AdvancedTable store="teamUsers" :fields="fields">
        <template #cell(full_name)="data">
          <router-link to="/account" v-if="isSelf(data)">{{
            data.value
          }}</router-link>
          <router-link
            v-else-if="!isTeamOwner(data)"
            :to="{ name: 'update-user', params: { id: data.item.id } }"
          >
            {{ data.value }}
          </router-link>
          <div v-else>{{ data.value }}</div>
        </template>
        <template #cell(actions)="data">
          <b-button
            @click="handleEdit(data)"
            variant="warning"
            class="btn btn-info btn-icon btn-sm"
            :disabled="isSelf(data) || isTeamOwner(data)"
            v-b-tooltip.hover
            :title="$gettext('Edit')"
            ><i class="fa fa-edit"></i
          ></b-button>
          <b-button
            @click="handleDelete(data)"
            variant="danger"
            class="btn btn-info btn-icon btn-sm ml-1"
            :disabled="isSelf(data) || isTeamOwner(data)"
            v-b-tooltip.hover
            :title="$gettext('Delete')"
            ><i class="fa fa-times"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { msgBoxConfirm } from "@/utils/modal";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: { EmptyState, ContentHeader, AdvancedTable },
  computed: {
    ...mapGetters("teamUsers", [
      "productUsage",
      "productLimit",
      "hasReachedProductLimit",
      "showBlankState"
    ]),
    ...mapGetters("teams", { teams: "items" }),
    ...mapGetters("me", { currentUser: "user" }),
    fields() {
      return [
        { key: "full_name", sortable: true, label: this.$gettext("Name") },
        { key: "email", sortable: true, label: this.$gettext("Email") },
        {
          key: "phone_number",
          sortable: true,
          label: this.$gettext("Phone Number")
        },
        {
          key: "pivot.status",
          sortable: true,
          label: this.$gettext("Status")
        },
        {
          key: "actions",
          tdClass: "td-actions-2",
          label: this.$gettext("actions")
        }
      ];
    },
    userLimitMessage() {
      let translated = this.$ngettext(
        "%{ usage } user over your limit of %{ limit }",
        "%{ usage } users over your limit of %{ limit }",
        this.productUsage
      );
      return this.$gettextInterpolate(translated, {
        usage: this.productUsage,
        limit: this.productLimit
      });
    }
  },
  methods: {
    ...mapActions("teamUsers", ["delete"]),
    handleEdit({ item }) {
      this.$router.push({ name: "update-user", params: { id: item.id } });
    },
    handleDelete({ item }) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.delete(item.id);
        }
      });
    },
    isTeamOwner({ item }) {
      const team =
        this.teams.find(team => team.id === this.currentUser.team_id) || {};
      return item.id === team.owner_id;
    },
    isSelf({ item }) {
      return item.id === this.currentUser.id;
    }
  }
};
</script>
